
import React from 'react'
import styles from './SettingsWindow.module.scss'
import CloseWindowButton from '../CloseWindowButton/CloseWindowButton';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
  closeWindowFunction: Function;
}

export const SettingsWindow: React.FC<LayoutProps> = ({ children, title, closeWindowFunction }) => {
  return (
    <div className={styles.main}>
      <span className={styles.heading}>
        <h3>{title}</h3>
        <CloseWindowButton closeWindowFunction={closeWindowFunction} />
      </span>

      {children}
    </div>
  )
}
