

export function usePlausibleEvent() {
    const plausible = (window as any).plausible;

    const recordEvent = (eventName: string, props?: any) => {
        if (plausible) {
            plausible(eventName);

        } else {

        }
    }

    return { recordEvent }
}