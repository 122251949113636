import React, { useContext } from 'react'

import { Fixture, Team } from '../../../domain/model'

import { FDRContext } from './FDRContext'

export function useDetermineDifficulty() {

    const { teams, fdrSetting, customFDR } = useContext(FDRContext)

    function determineDifficulty(team: Team, fixture: Fixture, fdr: "attack" | "defense") {
        if (fdrSetting === "standard") {
            return team.id === fixture.team_h.id ? fixture.team_h_difficulty : fixture.team_a_difficulty
        }

        if (fdrSetting === "custom") {
            const playingAtHome = fixture.team_h.id === team.id
            const opponent = teams.find(t => {
                return playingAtHome ? fixture.team_a.id === t.id : fixture.team_h.id === t.id

            })

            if (opponent === undefined) {
                throw new Error("opponent not found")
            }

            if (fdr === "attack") {
                return customFDR[opponent.short_name] ? customFDR[opponent.short_name].defending_strength : 1
            }

            if (fdr === "defense") {
                return customFDR[opponent.short_name] ? customFDR[opponent.short_name].attacking_strength : 1
            }
        }

        if (fdrSetting === "fplstrat") {
            const playingAtHome = fixture.team_h.id === team.id
            const opponent = teams.find(t => {
                return playingAtHome ? fixture.team_a.id === t.id : fixture.team_h.id === t.id

            })



            if (opponent === undefined) {
                throw new Error("opponent not found")
            }

            if (playingAtHome && fdr === "attack") {
                //console.log(`${team.short_name} against ${opponent.short_name} at home with opponent defence strength away ${opponent.strength_defence_away} `)
                return opponent.strength_defence_away
            }

            if (playingAtHome && fdr === "defense") {
                //console.log(`${team.short_name} against ${opponent.short_name} at home with opponent attack strength away ${opponent.strength_attack_away} `)
                return opponent.strength_attack_away
            }

            if (!playingAtHome && fdr === "attack") {
                //console.log(`${team.short_name} against ${opponent.short_name} away with opponent defence strength home ${opponent.strength_defence_home} `)
                return opponent.strength_defence_home
            }

            if (!playingAtHome && fdr === "defense") {
                //console.log(`${team.short_name} against ${opponent.short_name} away with opponent attack strength home ${opponent.strength_attack_home} `)
                return opponent.strength_attack_home
            }


        }

        return 1

    }


    return { determineDifficulty };

}
