import React, { useEffect, useState, Suspense, lazy, useContext } from 'react'
import styles from './TransferPlanner.module.scss'

import { getFutureFixtures, getPlanListLocally, getPlayers, getPlans, getPlan, planPrefix } from '../../fpl/api'
import CreatePlan from '../CreatePlan/CreatePlan'



import { Fixture, Player } from '../../../../domain/model'

import { Plan } from '../../../../domain/model'
import { SelectPlan } from '../SelectPlan/SelectPlan'
import { Header } from '../Header/Header'

import Footer from '../Footer/Footer'
import { AuthContext } from '../../Hooks/AuthContext'
import { useLocation } from 'react-router-dom'
import { useSavePlan } from '../../Hooks/useSavePlan'
import { HasChangedContext } from '../../Hooks/HasChangedContext'
import { Helmet } from 'react-helmet'
import Loading from '../Loading/Loading'
import { FDRContext } from '../../Hooks/FDRContext'
import { usePlausibleEvent } from '../../Hooks/usePlausibleEvent'
import { ToastContext } from '../../Hooks/ToastContext'

const Planner = lazy(() => import('../Planner/Planner'))

export type Team = {
  id: number,
  name: string,
  short_name: string,
  strength_defence_home: number,
  strength_defence_away: number,
  strength_attack_home: number,
  strength_attack_away: number,
}

function TransferPlanner() {

  let didInit = false;
  const [planList, setPlanList] = useState<Plan[]>([]);

  const [plan, setPlan] = useState<Plan | null>(null);
  const [fixtures, setFixtures] = useState<Fixture[]>([]);

  const { setTeams } = React.useContext(FDRContext)

  const [nextGameweek, setNextGameweek] = useState<number>(99);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const { isAuthenticated, csrfToken, isAuthenticating, justRegistered, justVerified, emailVerified, setJustRegistered } = React.useContext(AuthContext)

  const location = useLocation()

  const { recordEvent } = usePlausibleEvent()

  const { savePlan } = useSavePlan(plan)

  const { hasChanged, setHasChanged } = React.useContext(HasChangedContext)
  const { createToast } = React.useContext(ToastContext)



  useEffect(() => {
    if (!didInit) {
      didInit = true;
      fetchData()
    }

  }, [])

  useEffect(() => {
    if (!isAuthenticating) {

      fetchPlans()

    }

  }, [isAuthenticated, isAuthenticating])

  useEffect(() => {

    if (!plan && justVerified) {
      createToast("positive", "email verified! you can now create up to 10 plans.")
    }

    if (!plan && justRegistered) {
      createToast("positive",
        `Registered successfully! ${(justRegistered && !emailVerified) ? "We sent an email to confirm your address. Until your email address is confirmed you are limited to 2 saved plans." : "You can now create up to 10 plans."}`
      )
      recordEvent("signup")
      setJustRegistered(false)
    }
  }, [isAuthenticated, isAuthenticating])

  async function fetchPlans() {

    if (isAuthenticated) {
      setLoading(true)
      const plans = await getPlans(csrfToken)

      if (!plans) {
        createToast("negative", "Error loading plans! Please refresh the page to try again.")
        setPlanList([])
      } else {
        setPlanList(plans)
      }

      setLoading(false)
    } else {
      const plans = localStorage.getItem(planPrefix + "plans")

      if (plans) {
        setPlanList(JSON.parse(plans))
      }
      setLoading(false)
    }



  }

  async function fetchData() {
    const data = await getFutureFixtures()
    setFixtures(data.fixtures);
    setNextGameweek(data.nextEventId);
    setTeams(data.teams);

    if (!isAuthenticating) {
      setLoading(false)
    }


  }


  async function handlePlanSelect(id: number, currentPlan?: Plan | null) {

    if (currentPlan) {
      if (hasChanged) {
        await savePlan()
        createToast("positive", `saved plan ${currentPlan.name}`)
      }


    }
    const selectedPlan = isAuthenticated ? await getPlan(id, csrfToken) : getPlanListLocally().map(p => p).find(p => p.id === Number(id))

    if ((selectedPlan !== undefined) && !isAuthenticated) {

      await updatePlayers(selectedPlan)
      recordEvent("opened local plan")

    }

    if (selectedPlan && isAuthenticated) {
      recordEvent("opened user plan")
    }

    setPlan(selectedPlan ? selectedPlan : null)
    setHasChanged(false)
  }

  async function updatePlayers(planToUpdate: Plan) {

    const playersToUpdate: number[] = []
    planToUpdate.event_states.forEach(eventState => eventState.players.forEach(player => playersToUpdate.includes(player.id) ? null : playersToUpdate.push(player.id)))
    const players: Player[] = await getPlayers(playersToUpdate)
    const idToPlayer: { [key: number]: Player } = {}
    players.forEach(player => idToPlayer[player.id] = player)
    planToUpdate.event_states.forEach(eventState => eventState.players.forEach(player => {
      if (idToPlayer[player.id] === undefined) {
        return
      }
      player.chance_of_playing_next_round = idToPlayer[player.id].chance_of_playing_next_round
      player.news = idToPlayer[player.id].news
      player.expected_goals = idToPlayer[player.id].expected_goals
      player.expected_assists_per_90 = idToPlayer[player.id].expected_assists_per_90
      player.expected_goals_per_90 = idToPlayer[player.id].expected_goals_per_90
      player.expected_goal_involvements_per_90 = idToPlayer[player.id].expected_goal_involvements_per_90
      player.goals_scored = idToPlayer[player.id].goals_scored
      player.assists = idToPlayer[player.id].assists
      player.now_cost = idToPlayer[player.id].now_cost
      player.total_points = idToPlayer[player.id].total_points
      player.points_per_game = idToPlayer[player.id].points_per_game
      player.selected_by_percent = idToPlayer[player.id].selected_by_percent
      player.yellow_cards = idToPlayer[player.id].yellow_cards
      player.bought_for = player.bought_for
      player.minutes = idToPlayer[player.id].minutes
    }))


  }

  async function handleCreateNewPlan() {

    await savePlan()
    createToast("positive", `saved plan ${plan?.name}!`)


    setPlan(null)
  }



  return (
    <div className={styles.main}>
      <Helmet>
        <meta name="description" content="Crush your Fantasy Premier League competition using the xG-based fplstrat FPL Planner App designed for mobile!" />
        <title>FPL Planner App</title>
        <link rel="canonical" href="https://fplstrat.app/planner" />

      </Helmet>

      <Header plan={plan} planList={planList} handlePlanSelect={handlePlanSelect} handleCreateNewPlan={handleCreateNewPlan} notInPlan={plan ? false : true} />
      {!plan && <h1 className={styles.heading}>Fpl Planner App</h1>}


      {!plan && error !== "" && <p className={styles.error}>{error}</p>}

      <div className={`${styles.app} ${!plan && styles.noBorder}`}>
        {
          plan ? (
            <Suspense fallback={<div className={styles.loadingContainer}><Loading text='loading plan' /></div>}>
              <Planner userPlan={plan} fixtures={fixtures} setUserPlan={setPlan} nextGameweek={nextGameweek} key={plan.id} fetchPlans={fetchPlans} />
            </Suspense>
          ) : (
            <div className={styles.plansMenu}>
              {loading || isAuthenticating ? <>
                <div className={styles.loadingContainer}>
                  <Loading text='loading...' />
                </div>


              </> :
                (
                  <>

                    {((isAuthenticated && emailVerified && planList.length < 10) || (isAuthenticated && !emailVerified && planList.length < 2) || ((!isAuthenticated) && planList.length < 1)) && <CreatePlan setPlan={setPlan} nextGw={nextGameweek} setPlanList={setPlanList} planList={planList} />}
                    <SelectPlan handleSelectPlan={handlePlanSelect} planList={planList} setPlan={setPlan} setPlanList={setPlanList} updatePlayers={updatePlayers} />

                  </>
                )

              }


            </div>
          )
        }
      </div>



      {!plan && (
        <Footer />
      )}
    </div>
  )
}
export default TransferPlanner