import React, { useState } from 'react';
import styles from './CreatePlan.module.scss';
import { createPlan, createPlanLocal, getPlanListLocally, getPlans, savePlanLocally } from '../../fpl/api';

import loadingSpinner from "./loading.svg"
import helpSvg from "./help.svg"
import { Plan } from '../../../../domain/model';
import { AuthContext } from '../../Hooks/AuthContext';
import { usePlausibleEvent } from '../../Hooks/usePlausibleEvent';

type CreatePlanProps = {
    setPlan: Function,
    setPlanList: Function,
    nextGw: number,
    planList: Plan[]
};

type FormValues = {
    planName: string,
    fplTeamId: string,
    planToCopy?: string
}

type FormErrors = {
    planName?: string,
    fplTeamId?: string,
    planToCopy?: string
}



export function CreatePlan({ setPlan, setPlanList, nextGw, planList }: CreatePlanProps) {
    const storedId = localStorage.getItem("fplTeamId");
    const [formValues, setFormValues] = useState<FormValues>({ planName: "", fplTeamId: (storedId && (nextGw > 1)) ? storedId : "", planToCopy: "" });
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    const [fplTeamIdError, setFplTeamIdError] = useState("");
    const [creatingPlan, setCreatingPlan] = useState(false);
    const [error, setError] = useState("")

    const [rememberTeamId, setRememberTeamId] = useState(true);
    const [copyPlan, setCopyPlan] = useState(false);

    const [showHelp, setShowHelp] = useState(false);

    const { isAuthenticated, csrfToken } = React.useContext(AuthContext);

    const { recordEvent } = usePlausibleEvent();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })
    }

    const validate = (values: FormValues) => {
        setError("")
        const errors: FormErrors = {}
        const idRegex = /^\d+$/;

        if (!values.planName) {
            errors.planName = "Plan name is required"
        }

        if (copyPlan && !values.planToCopy) {
            errors.planToCopy = "Select a plan to copy"
        }

        if (nextGw > 1) {
            if (!values.fplTeamId) {
                errors.fplTeamId = "Team ID is required"
            } else if (!idRegex.test(values.fplTeamId)) {
                errors.fplTeamId = "Team ID must be a number"
            } else if (Number(values.fplTeamId) < 1) {
                errors.fplTeamId = "Team ID must be greater than 0"
            }
        }


        return errors;
    }

    const handleCreatePlan = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault()

        const errors = validate(formValues);

        setFormErrors(errors);

        // Logic to create the plan goes here
        if (hasOnlyDigits(formValues.fplTeamId) && formValues.planName !== "" && errors.planToCopy === undefined && errors.fplTeamId === undefined || nextGw === 1 && formValues.planName !== "" && errors.planToCopy === undefined) {
            setCreatingPlan(true);

            (rememberTeamId && formValues.fplTeamId !== "") ? localStorage.setItem("fplTeamId", formValues.fplTeamId) : localStorage.removeItem("fplTeamId");

            if (!isAuthenticated) {


            }
            const planResponse = isAuthenticated ? await createPlan(formValues.planName, formValues.fplTeamId, csrfToken, formValues.planToCopy) : await createPlanLocal(formValues.planName, formValues.fplTeamId)



            if (planResponse.id !== undefined) {
                !isAuthenticated && savePlanLocally(planResponse)
                setPlan(planResponse);
                setCreatingPlan(false);
                setPlanList(isAuthenticated ? await getPlans(csrfToken) : getPlanListLocally());
                recordEvent("plan created")

            } else {
                setError(planResponse.error)
                setCreatingPlan(false);
            }

        }
    };


    function hasOnlyDigits(value: string) {
        return /^\d+$/.test(value);
    }

    function handleCopyPlanChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { id } = e.target;
        setCopyPlan(id === "copyPlanTrue" ? true : false)
    }

    return (
        <>

            {/* <section className={styles.info}>
        <p className={styles.description}>enter your fpl team id, a name for you plan and start planning your transfers for the upcoming gameweeks!</p>
      </section> */}
            {showHelp ? (
                <div className={styles.help}>
                    <button onClick={() => { setShowHelp(false) }} className={styles.closeHelp}>X</button>
                    <h4>Finding your FPL team ID</h4>
                    <p>1. Log in to the official <a href="https://fantasy.premierleague.com/" target="_blank" rel="noreferrer">FPL website</a> </p>
                    <p>2. Click on the "Points" tab</p>
                    <p>3. Look at the URL in your browser. It should look like this:</p>
                    <p>https://fantasy.premierleague.com/ entry/1234567/event/1</p>
                    <p>The number to the right of /entry/ is your ID!</p>

                </div>
            ) : null}

            <form className={styles.form} onSubmit={handleCreatePlan}>
                <h1 className={styles.createPlanHeader}>Create Plan</h1>
                <p className={styles.subtitle}>Just enter a name for your plan & your FPL team ID to get started!</p>
                <label htmlFor="planName">plan name:</label>
                <div className={styles.inputContainer}>
                    <input id='planName' className={styles.input} maxLength={20} name='planName' type="text" placeholder='enter plan name' value={formValues.planName} onChange={handleChange} />

                </div>
                {formErrors.planName && <p className={styles.error}>{formErrors.planName}</p>}
                {
                    nextGw > 1 ? (
                        <>
                            <label htmlFor="fplTeamId">FPL team ID:</label>
                            <div className={styles.inputContainer}>
                                <input id='fplTeamId' name='fplTeamId' className={fplTeamIdError === "" ? styles.input : styles.fplTeamIdError} type='text' placeholder='enter team id' value={formValues.fplTeamId} onChange={handleChange} />
                                <img onClick={() => { setShowHelp(true) }} src={helpSvg} className={styles.helpSvg} alt="" />

                            </div>
                            {formErrors.fplTeamId && <p className={styles.error}>{formErrors.fplTeamId}</p>}
                            <div className={styles.checkbox}>
                                <input type="checkbox" id="mustHaveFixture" name="mustHaveFixture" checked={rememberTeamId} onChange={() => setRememberTeamId(!rememberTeamId)} />
                                <label htmlFor="mustHaveFixture">remember team id</label>
                            </div>
                            {formValues.fplTeamId.length > 0 && (<p className={styles.error}>{fplTeamIdError}</p>)}
                        </>

                    ) : (
                        null
                    )
                }

                {
                    planList.length > 0 && (
                        <fieldset className={styles.copyPlanRadio}>
                            <label>copy an existing plan? </label>
                            <input name='copy' type="radio" id='copyPlanTrue' checked={copyPlan === true} onChange={handleCopyPlanChange} />
                            <label htmlFor='copyPlanTrue'>yes</label>
                            <input name='copy' type="radio" id='copyPlanFalse' checked={copyPlan === false} onChange={handleCopyPlanChange} />
                            <label htmlFor='copyPlanFalse'>no</label>
                        </fieldset>
                    )
                }

                {
                    copyPlan && isAuthenticated && (
                        <>
                            <label>select plan to copy:</label>
                            <select className={styles.planToCopySelect} value={formValues.planToCopy} onChange={handleChange} name="planToCopy" id="planToCopy">
                                {planList.map((plan, index) => {
                                    return <option key={index} value={plan.id}>{plan.name}</option>
                                })}
                                <option value="" disabled>select plan</option>
                            </select>
                            {formErrors.planToCopy && <p className={styles.error}>{formErrors.planToCopy}</p>}
                        </>


                    )
                }



                <button type='submit' className={`${styles.createBtn} plausible-event-name=click+create+plan`}>{creatingPlan ? (<img src={loadingSpinner}></img>) : ("create")}</button>
                {error !== "" && <p className={styles.error}>{error}</p>}


            </form>



        </>


    );
};

export default CreatePlan;
