import { Plan } from "../../../domain/model";
import { minimisePlan, savePlanStateLocally } from "../fpl/api";
import { AuthContext } from "./AuthContext";
import React from "react";
import { HasChangedContext } from "./HasChangedContext";

export function useSavePlan(plan: Plan | null, askForConfirmation: boolean = true) {
    const { isAuthenticated, csrfToken } = React.useContext(AuthContext)
    const { hasChanged, setHasChanged } = React.useContext(HasChangedContext)

    async function savePlan() {
        if (!plan) {
            return false
        }
        if (isAuthenticated && hasChanged) {

            if (askForConfirmation && confirm(`save changes to plan ${plan.name}?`) === false) {
                return false
            }

            const minimisedPlan = minimisePlan(plan)
            const response = await fetch("/api/plans/", {
                method: 'PUT',
                body: JSON.stringify(minimisedPlan),
                headers: {
                    'Content-Type': 'application/json',
                    'csrf-token': csrfToken
                }
            })
            if (response.ok) {
                setHasChanged(false)
                return true
            } else {
                return false
            }
        } else if (!isAuthenticated && hasChanged) {

            savePlanStateLocally(plan)
            setHasChanged(false)
            return true
        }
    }

    return { savePlan }


}