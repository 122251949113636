import React from 'react'
import styles from './AboutPage.module.scss'
import { StandardLayout } from '../StandardLayout/StandardLayout'
import { Link } from 'react-router-dom'

export default function AboutPage() {
    return (
        <StandardLayout canonicalUrl='https://fplstrat.app/about' metaTitle='fplstrat.app | about' metaDescription='Learn about fplstrat.app, the ultimate xG-based fpl planner for mobile!'>

            <section className={styles.aboutSection}>
                <h1 className={styles.aboutTitle}>About fplstrat.app</h1>
                <p className={styles.aboutParagraph}>
                    Hi! I'm Friedrich Panse, the creator of fplstrat.app. I'm a web developer and I made this thing for two reasons: I love playing Fantasy Premier League, and I didn't like how the other FPL planners out there felt on my phone! <br></br>
                    <br></br>
                    The fplstrat.app <Link to="/planner">Planner</Link> then is the result of my efforts and love for the game. It's designed to be fast, easy-to-use and give you a basic stat overview of players of your team or that you might want in your team, inspiring transfer decisions that you maybe would not have thought of otherwise! <br></br>
                    <br></br>
                    While creating this app I noticed that there is no planner out there that combines a quality UI with an xG based difficulty rating differentiating between attacking and defensive difficulty. In the process of adding this FDR, I almost accidentally made the fplstrat <Link to="/fpl-fixture-ticker">Ticker</Link>, which applies the same mobile-first principles to an FPL fixture ticker.
                    <br></br>
                    <br></br>
                    I'll always keep improving the site, and I hope you enjoy using fplstrat.app as much as I enjoyed creating it! If you have any feedback or suggestions, feel free to hit me up on <a href="https://twitter.com/fplstratapp?ref_src=twsrc%5Etfw">X</a>!
                    <br></br>
                    <br></br>
                    - Fred
                </p>

            </section>

        </StandardLayout>
    )
}
