import React from 'react'
import styles from './Footer.module.scss'
import xLogo from "./x-sm.png"
import logoSvg from "./logo.svg"

import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer className={styles.footer}>
            <section className={styles.copyrightSection}>
                <div className={styles.title}>
                    <img alt='logo' src={logoSvg}></img>
                    <span >fplstrat.app</span>
                </div>

                <p className={styles.copyright}>© 2024 fplstrat.app </p>
                <a href='/privacy-policy.html'>Privacy Policy</a>

            </section>

            <nav className={styles.nav}>
                <ul >
                    <li><Link to="/">home</Link></li>
                    <li><Link to="/planner">planner</Link></li>
                    <li><Link to="/fpl-fixture-ticker">ticker</Link></li>
                    <li><Link to="/contact">contact</Link></li>
                    <li><Link to="/about">about</Link></li>
                </ul>
            </nav>

            <a href="https://twitter.com/fplstratapp?ref_src=twsrc%5Etfw" className={styles.followBtn} data-show-count="false"><img src={xLogo} loading='lazy' alt="" /></a><script async src="https://platform.twitter.com/widgets.js"></script>



        </footer>
    )
}
