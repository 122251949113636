import { useContext, useState } from 'react'
import { CustomFDR, FDRContext } from '../../Hooks/FDRContext'
import { SettingsWindow } from '../SettingsWindow/SettingsWindow'
import CloseWindowButton from '../CloseWindowButton/CloseWindowButton'
import styles from './CustomFDRSettings.module.scss'
import { ToastContext } from '../../Hooks/ToastContext'

export default function CustomFDRSettings({ closeCustomFDRSettings }: { closeCustomFDRSettings: Function }) {

    const { teams, setTeams, customFDR, setCustomFDR } = useContext(FDRContext)
    const { createToast } = useContext(ToastContext)
    const [newCustomFDR, setNewCustomFDR] = useState<CustomFDR>({ ...customFDR })

    const sortedTeams = teams.sort((a, b) => a.short_name.localeCompare(b.name))

    const handleCustomFDRChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target
        const [team, fdr] = name.split(".")

        const type = fdr === "attack" ? "attacking_strength" : "defending_strength"

        setNewCustomFDR(prev => {
            return {
                ...prev,
                [team]: {
                    ...prev[team],
                    [type]: Number(value)
                }
            }
        })

    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setCustomFDR(newCustomFDR)
        createToast("positive", "Custom FDR settings saved")
        closeCustomFDRSettings()

    }

    return (
        <SettingsWindow title='Custom FDR Settings' closeWindowFunction={closeCustomFDRSettings}>
            <form className={styles.teams} onSubmit={handleSubmit} >
                <table className={styles.table}>
                    <thead className={styles.head}>
                        <th>Team</th>
                        <th>Attack Strength</th>
                        <th>Defensive Strength</th>
                    </thead>
                    <tbody>
                        {
                            sortedTeams.map((team, index) => {
                                return (
                                    <tr key={index} className={styles.team}>
                                        <td className={styles.rowTitle}>
                                            <h4>{team.name}</h4>
                                        </td>

                                        <td className={styles.tableSelect}>
                                            <select name={`${team.short_name}.attack`} onChange={handleCustomFDRChange}>
                                                {
                                                    Array(10).fill(0).map((_, i) => {
                                                        return <option key={i} value={i + 1} selected={newCustomFDR[team.short_name].attacking_strength === i + 1}>{i + 1}</option>
                                                    })
                                                }
                                            </select>
                                        </td>





                                        <td className={styles.tableSelect}>
                                            <select name={`${team.short_name}.defense`} onChange={handleCustomFDRChange}>
                                                {
                                                    Array(10).fill(0).map((_, i) => {
                                                        return <option key={i} value={i + 1} selected={newCustomFDR[team.short_name].defending_strength === i + 1}>{i + 1}</option>
                                                    })
                                                }
                                            </select>
                                        </td>



                                    </tr>
                                )
                            })
                        }
                    </tbody>



                </table>

                <button type="submit" className={styles.saveBtn}>Save</button>

            </form>

        </SettingsWindow>
    )
}
