import styles from './HeroPage.module.scss'

import { Link } from 'react-router-dom'

import { StandardLayout } from '../StandardLayout/StandardLayout'

import redArrow from './red-arrow.svg'
import greenArrow from './green-arrow.svg'

function HeroPage() {
    return (
        <StandardLayout
            canonicalUrl='https://fplstrat.app'
            metaDescription='Gain Fantasy Premier League Rank with our FPL Transfer Planner! Featuring a powerful Fixture Ticker, xG based FDR and Player Statistics.'
            metaTitle='FPL Transfer Planner'>



            <section className={styles.mainSection}>
                <section className={styles.heroTitleSection}>
                    <h1 className={styles.seoTitle}>FPL Transfer Planner</h1>
                    <p className={styles.heroTitle}>Turn <img src={redArrow} alt="red arrow down" />  into  <img src={greenArrow} alt="green arrow up" />!<br></br></p>
                    <p className={styles.heroSubtitle}>
                        Gain an edge on your mini-league opponents using the <span className={styles.highlight}>best Planning UI</span> of any free FPL Transfer Planner,
                        a <span className={styles.highlight}>powerful Fixture Ticker</span>, a <span className={styles.highlight}>smart Stat Overview for Players</span> and


                        <span className={styles.highlight}> (optional) xG based FDR. </span><br /><br />
                        Start planning now and <span className={styles.highlight}> turn your Fantasy Premier League season around! 📈📈</span>

                    </p>



                    <div className={styles.btnContainer}>
                        <Link className={`${styles.startPlanningBtn} plausible-event-name=click+start+planning`} to="/planner" >{"->"} start planning!</Link>

                    </div>


                </section>

                <section className={styles.screenshots}>

                    <section className={styles.heroImageSection}>

                        <img className={styles.screenshot} srcSet="screenshot-sm.webp 224w, screenshot.webp 618w"
                            sizes="(min-width: 450px) 618px, 224px"
                            alt="fpl-transfer-planner screenshot" />
                        <p><Link to={"/planner"}>planner</Link> with stats overview opened</p>
                    </section>

                    <section className={styles.heroImageSection}>
                        <img className={styles.screenshot} srcSet="ticker-screenshot-sm.webp 224w, ticker-screenshot.webp 618w"
                            sizes="(min-width: 450px) 618px, 224px"
                            alt="fpl-fixture-ticker screenshot" />
                        <p><Link to={"/fpl-fixture-ticker"}>fixture ticker</Link> with xG based FDR enabled</p>

                    </section>
                </section>

            </section>


        </StandardLayout>
    )
}

export default HeroPage