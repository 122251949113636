import React, { useEffect, useState } from "react";
import { Team } from "../Components/TransferPlanner/TransferPlanner";

type FDRContextType = {
    teams: Team[],
    setTeams: (teams: Team[]) => void
    fdrSetting: "standard" | "fplstrat" | "custom",
    showTeamNames: boolean,
    setShowTeamNames: (show: boolean) => void
    setFDRSetting: (setting: "standard" | "fplstrat" | "custom") => void
    customFDR: CustomFDR
    setCustomFDR: (customFDR: CustomFDR) => void
}

export const FDRContext = React.createContext<FDRContextType>({
    teams: [],
    setTeams: () => { },
    fdrSetting: "standard",
    showTeamNames: true,
    setShowTeamNames: () => { },
    setFDRSetting: () => { },
    customFDR: {},
    setCustomFDR: () => { }
})

export type CustomFDR = { [key: string]: { "attacking_strength": number, "defending_strength": number } }


export default function FDRProvider({ children }: { children: React.ReactNode }) {
    const storedCustomFDR = localStorage.getItem("customFDR")
    const stored = localStorage.getItem("fdrSetting")
    const storedShowTeamNames = localStorage.getItem("showTeamNames")

    const [teams, setTeams] = React.useState<Team[]>([])
    const [fdrSetting, setFDRSetting] = React.useState<"standard" | "fplstrat" | "custom">(stored ? stored as "standard" | "fplstrat" | "custom" : "standard")

    const [showTeamNames, setShowTeamNames] = React.useState<boolean>(storedShowTeamNames ? storedShowTeamNames === "true" : false)

    const [customFDR, setCustomFDR] = useState<CustomFDR>(storedCustomFDR ? JSON.parse(storedCustomFDR) : {})

    useEffect(() => {
        localStorage.setItem("fdrSetting", fdrSetting)
    }, [fdrSetting])

    useEffect(() => {
        localStorage.setItem("showTeamNames", showTeamNames.toString())
    }, [showTeamNames])

    useEffect(() => {
        localStorage.setItem("customFDR", JSON.stringify(customFDR))
    }, [customFDR])

    useEffect(() => {
        Object.keys(customFDR).length < teams.length && setCustomFDR(
            {
                ...Object.fromEntries(teams.map(team => [team.short_name, { "attacking_strength": Number(team.strength_attack_home), "defending_strength": Number(team.strength_defence_home) }]))
            }
        )
    }, [teams])

    return (
        <FDRContext.Provider value={{ teams, setTeams, setCustomFDR, fdrSetting, setFDRSetting, showTeamNames, setShowTeamNames, customFDR }}>
            {children}
        </FDRContext.Provider>
    )
}